// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import '@rails/ujs'
//
// import Turbolinks from 'turbolinks'
// Turbolinks.start()
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '@hotwired/turbo-rails'
import './../controllers'

import '../src/toggleElement'
import '../src/scrolltop'
import '../src/dmak.min'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

// Font Awesome
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

import { createApp, reactive } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import { createHead } from '@unhead/vue'
import VueSafeHTML from 'vue-safe-html'
import messages from '$/locales/messages'
import router from '../src/router'
import App from '../App.vue'

const GStore = reactive({ flashMessage: { content: null, type: 'notice' } })

const pinia = createPinia()
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages
})
const head = createHead()

import AppAdsense from '../components/AppAdsense.vue'
import AppLoading from '../components/AppLoading.vue'

import 'flowbite'
import '@/builds/tailwind.css'

document.addEventListener('DOMContentLoaded', () => {
  createApp(App)
    .use(i18n)
    .use(router)
    .use(pinia)
    .use(head)
    .use(VueSafeHTML)
    .provide('GStore', GStore)
    .component('AppAdsense', AppAdsense)
    .component('AppLoading', AppLoading)
    .mount('#app')
})
